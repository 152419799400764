// this file is automatically generated.
// !!! DO NOT EDIT !!!

import _ from 'lodash'



export let dirtyFlag = false
function doAlert(s) { console.log(s) }

export type Action =
  | 'return'
  | 'update'
  | 'delete'
  | 'confUser'
  | 'sqlTest'

export const Action_as_strings = [ 'return', 'update', 'delete', 'confUser', 'sqlTest' ] as const;



export class Request {
  private '_action': Action
  private '_customer': string
  private '_datascope': string | null
  private '_userConf': UserConf | null
  private '_sqlTest': SqlTestConf | null

  constructor() {
    this['_action'] = 'return'
    this['_customer'] = ''
    this['_datascope'] = null
    this['_userConf'] = null
    this['_sqlTest'] = null
  }
  get action(): Action { return this['_action'] }
  set action(__a__: any) {
    let __v__ = enumReader(['return','update','delete','confUser','sqlTest'],'return')(__a__)
    if(!_.isEqual(__v__,this['_action'])) {
      setDirtyFlag()
      this['_action'] = __v__
    }
  }
  get customer(): string { return this['_customer'] }
  set customer(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_customer'])) {
      setDirtyFlag()
      this['_customer'] = __v__
    }
  }
  get datascope(): string | null { return this['_datascope'] }
  set datascope(__a__: any) {
    if(__a__ == null) {
      if(this['_datascope'] == null) { return }
      setDirtyFlag()
      this['_datascope'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_datascope'])) {
        setDirtyFlag()
        this['_datascope'] = __v__
      }
    }
  }
  get userConf(): UserConf | null { return this['_userConf'] }
  set userConf(__a__: any) {
    if(__a__ == null) {
      if(this['_userConf'] == null) { return }
      setDirtyFlag()
      this['_userConf'] = null
      return
    } else {
      setDirtyFlag()
      this['_userConf'] = __a__
    }
  }
  get sqlTest(): SqlTestConf | null { return this['_sqlTest'] }
  set sqlTest(__a__: any) {
    if(__a__ == null) {
      if(this['_sqlTest'] == null) { return }
      setDirtyFlag()
      this['_sqlTest'] = null
      return
    } else {
      setDirtyFlag()
      this['_sqlTest'] = __a__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Request {
    disableDF()
    let cls = new Request()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.action = __a__['action']
       cls.customer = __a__['customer']
       cls.datascope = __a__['datascope'] == null ? null : __a__['datascope']
       cls.userConf = __a__['userConf'] == null ? null : UserConf.fromJson(__a__['userConf'])
       cls.sqlTest = __a__['sqlTest'] == null ? null : SqlTestConf.fromJson(__a__['sqlTest'])
    } else {
       doAlert(`Request: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class SqlTestConf {
  private '_database': string
  private '_schema': string
  private '_table': string

  constructor() {
    this['_database'] = ''
    this['_schema'] = ''
    this['_table'] = ''
  }
  get database(): string { return this['_database'] }
  set database(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_database'])) {
      setDirtyFlag()
      this['_database'] = __v__
    }
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get table(): string { return this['_table'] }
  set table(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_table'])) {
      setDirtyFlag()
      this['_table'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): SqlTestConf {
    disableDF()
    let cls = new SqlTestConf()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.database = __a__['database']
       cls.schema = __a__['schema']
       cls.table = __a__['table']
    } else {
       doAlert(`SqlTestConf: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class SqlTestResult {
  private '_columns': Array<string>
  private '_records': Array<Array<string>>

  constructor() {
    this['_columns'] = []
    this['_records'] = []
  }
  get columns(): Array<string> { return this['_columns'] }
  set columns(__a__: any) {
    setDirtyFlag()
    this['_columns'] = __a__
  }
  get records(): Array<Array<string>> { return this['_records'] }
  set records(__a__: any) {
    setDirtyFlag()
    this['_records'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): SqlTestResult {
    disableDF()
    let cls = new SqlTestResult()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.columns = array1Reader(stringReader(''))(__a__['columns'])
       cls.records = array2Reader(stringReader(''))(__a__['records'])
    } else {
       doAlert(`SqlTestResult: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class UserConf {
  private '_name': string
  private '_password': string
  private '_datascopes': Array<string>

  constructor() {
    this['_name'] = ''
    this['_password'] = ''
    this['_datascopes'] = []
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get password(): string { return this['_password'] }
  set password(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_password'])) {
      setDirtyFlag()
      this['_password'] = __v__
    }
  }
  get datascopes(): Array<string> { return this['_datascopes'] }
  set datascopes(__a__: any) {
    setDirtyFlag()
    this['_datascopes'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): UserConf {
    disableDF()
    let cls = new UserConf()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.name = __a__['name']
       cls.password = __a__['password']
       cls.datascopes = array1Reader(stringReader(''))(__a__['datascopes'])
    } else {
       doAlert(`UserConf: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

function stringReader(__dflt__) {
  return ((__a__) => {
    if(_.isString(__a__)) {
      return __a__
    }
    doAlert(`stringReader: ${__a__} is not a string`)
    return __dflt__
  })
}
function array1Reader(__r__) {
  return ((__a__) => {
    if(!_.isArray(__a__)) {
      doAlert(`arrayReader: ${__a__} is not an array`)
      return []
    }
    return __a__.map(__r__)
  })
}
function array2Reader(__r__) {
  return ((__a__) => {
    if(!_.isArray(__a__)) {
      doAlert(`arrayReader: ${__a__} is not an array`)
      return []
    }
    return __a__.map(array1Reader(__r__))
  })
}
function enumReader(__v__,__dflt__) {
  return ((__a__) => {
    if(!_.isString(__a__)) {
      doAlert(`enumReader: ${__a__} is not a string`)
      return __dflt__
    }
    if(__a__ !== __dflt__ && !_.includes(__v__,__a__)) {
      doAlert(`enumReader: ${__a__} is not in ${__v__}`)
      return __dflt__
    }
    return __a__
  })
}

function removeLeadingUnderscore(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(val => removeLeadingUnderscore(val));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((newObj, key) => {
      const newKey = ( key.length > 0 && key[0] === '_' ) ? key.substring(1) : key;
      newObj[newKey] = removeLeadingUnderscore(obj[key]);
      return newObj;
    }, {} as any)
  } else {
    return obj;
  }
}

let setDirtyFlag = () => { dirtyFlag = true }
let [disableDF,enableDF] = (() => {
    let n = 0
    return [() => {
      if(n === 0) {
        setDirtyFlag = () => {}
        n = 1
      } else {
        n += 1
      }
    },
    () => {
      if(n === 1) {
        setDirtyFlag = () => { dirtyFlag = true }
        n = 0
      } else {
        n -= 1
      }
    }]
})()
